@import "../../globals.scss";



.siteWrapper {
  perspective: 4000px; // for 3D flying and user perspective
  perspective-origin: 50% 0%;
  margin-inline: auto;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all $animationDuration ease-in-out;
  .homeBackgroundWrapper {
    transition: all $animationDuration ease-in-out;
    background: linear-gradient(
        to right,
        $fadeWhite 0%,
        $fadeWhiteClear 10%,
        $fadeWhiteClear 90%,
        $fadeWhite 100%
      ),
      linear-gradient(180deg, $fadeWhiteClear 86%, $fadeWhite 100%),
      url("../../../public/assets/cloud-top-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% top;
    background-origin: content-box;
    .homePaddingWrapper {
      position: relative;
      z-index: 0;
      //important clouds
      .cloud {
      
        position: absolute;
        animation: moveCloud linear forwards;
        pointer-events: none;
      }
      // <---- text pulled from here
    }

    &.flyingAnimation { 
     transform: translateZ(500px) translateY(-5vh);
    }

  
  }

  // important //animation important REQUIRES FULL PERSPECTIVE
}

@media (max-width: 1600px) {
  .siteWrapper {
    .homeBackgroundWrapper {
      background-size: calc(1100px + (2vw * 10));
      .homePaddingWrapper {
        padding-inline: 4vw;
      }
    }
  }
}

@media (max-width: 1200px) {
  .siteWrapper {
    .homeBackgroundWrapper {
      padding-inline: 3vw;
      .homePaddingWrapper {
        padding-inline: 0;
      }
    }
  }
}

// ------ CLOUD MOVEMENT ------ //

@keyframes moveCloud {
  0% {
    right: -12%; /* Start just outside the container on the right */
    opacity: 0;
  }
  10% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    right: 98%; /* Move just outside the container on the left */
  }
}

