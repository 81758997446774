.App {
  container-type: inline-size;
  position: relative;
  padding-inline: calc(max(6vw, (100vw - 1280px)/2));
  text-align: center;
  background-color: #4b5566;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 1400ms ease-in-out;

  &.animate-in {
    background-color: #8699bf;
  }

  .container {
    container-type: inline-size;
    position: relative;
    width: clamp(380px, 51%, 680px);
    aspect-ratio: 1/1;
    background-size: cover;

    #image0_436_92 {
      transform-origin: center;
      filter: blur(4px);
    }

    #scribble-mask-path {
      display: inline;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      path {
        stroke-miterlimit: 0;
        stroke-linecap: round;
        fill: transparent;
        stroke: transparent;
      }
    }

    @keyframes draw {
      to {
        stroke-dashoffset: 0;
      }
    }
  }

  h1#title {
    font-family: "Bitter", serif;
    font-weight: 300;
    font-style: italic;
    font-size: clamp(10px, 2.4cqi, 16px);
    letter-spacing: 0.15em;
    color: #ebe6d7;
    position: absolute;
    inset: 0;
    margin: auto;
    top: 22%;
    padding-right: 0.3em;
    opacity: 0;
    transition: opacity 1000ms ease-in-out; 
    &.animate-in {
      opacity: 1;
    }
  }

  p#coming-soon {
    font-family: "Bitter", serif;
    font-weight: 300;
    letter-spacing: 0.8em;
    font-size: calc(8px + 1.7vmin);
    color: #41251f;
    opacity: 0;
    letter-spacing: 0.8em;
    letter-spacing: 0.3em;
    color: #ded8c6;
    opacity: 1;
  }
}
