@import "../../globals.scss";

.footer {
  transition: all $animationDuration ease-in-out;
  .footerWrapper {
    background: linear-gradient(
        to right,
        $fadeWhite 0%,
        $fadeWhiteClear 14%,
        $fadeWhiteClear 86%,
        $fadeWhite 100%
      ),
      linear-gradient(180deg, $fadeWhite 4%, $fadeWhiteClear 18%),
      url("../../../public/assets/grass-extra-bottom-bg.png");

    background-repeat: no-repeat;
    background-position: 50% 46%;
    background-origin: content-box;
    height: 400px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-size: cover;
    p {
      text-align: center;
      color: #fff;
    }
  }
  &.flyingAnimation {
    padding-top: 20vh;
  }
}

@media (max-width: 1600px) {
  .footer {
    .footerWrapper {
      background-size: calc(1100px + (2vw * 10));
      font-size: clamp(12px, 2vw, 1em);
    }
  }
}